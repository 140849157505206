import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { toast } from 'react-toastify';

import { useHistory } from 'react-router-dom';

import { useCompanyContext } from '../../contexts/company';
import { NavigationUtils } from '../../utils';

import { list } from '../../processes/place';

import SelectPlaceForm from './SelectPlaceForm';

function SelectPlaceFormContainer() {
	const history = useHistory();
	const { scheduleSettings, form, settings, setInfo } = useCompanyContext();

	const [places, setPlaces] = useState([]);
	const [loading, setLoading] = useState(false);
	const [placeId, setPlaceId] = useState(form?.place?.id);

	const selectedPlace = useMemo(() => placeId ? places.find(place => place.id === ~~placeId) : null, [placeId, places]);

	const getPlaces = useCallback(async() => {
		const filter = {
			event_id: form?.event?.id
		};

		if (!filter.event_id) {
			return;
		}

		setLoading(true);

		const { data, error } = await list(filter);

		setLoading(false);

		if (error) {
			toast.error('Não foi possível buscar as unidades, tente novamente mais tarde.');
		} else {
			setPlaces(data);
		}
	}, [form?.event?.id])

	useEffect(() => {
		getPlaces();
	}, [getPlaces]);

	const handleNextStepPress = useCallback(() => {
		setInfo(values => ({
			...values,
			form: {
				...values.form,
				place: scheduleSettings.places.find(item => item.id === ~~placeId)
			}
		}));

		NavigationUtils.navigate(history, '/select-timegrid');
	}, [history, setInfo, placeId, scheduleSettings?.places]);

	const handleNavigateHome = useCallback(() => NavigationUtils.goBackHome(history), [history])

	const handleNavigateBackPress = useCallback(() => {
		if (placeId) {
			setInfo(values => ({
				...values,
				form: {
					...values.form,
					place: null
				}
			}));
		}

		NavigationUtils.navigate(history, '/select-event');
	}, [history, placeId, setInfo]);

    return (
		<SelectPlaceForm
			loading={loading}
			selectedPlaceId={placeId}
			places={places}
			selectedPlace={selectedPlace}
			variant={settings.theme?.name}
			onNextStepPress={handleNextStepPress}
			onSelectPlace={setPlaceId}
			handleNavigateBackPress={handleNavigateBackPress}
			handleNavigateHome={handleNavigateHome}
		/>
	);
}

export default SelectPlaceFormContainer;
