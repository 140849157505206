import styled from "styled-components";

export const Grid = {};

Grid.Row = styled.div`
	flex-direction: row;
	align-items: ${({ align = 'stretch' }) => align};
	justify-content: ${({ justify = 'flex-start' }) => justify};
`;

Grid.Column = styled.div`
	flex-direction: column;
	align-items: ${({ align = 'stretch' }) => align};
`;
