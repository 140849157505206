import styled from 'styled-components';

import { COLORS, SPACING } from '../../constants/theme';

const SEPARATOR_STYlES = {
	spacing: {
		default: SPACING.medium,
		small: SPACING.small
	}
};

const getSpacing = spacing => SEPARATOR_STYlES.spacing[spacing] || SEPARATOR_STYlES.spacing.default;

export const Separator = styled.div`
	width: 100%;
	height: 1px;
	background-color: ${COLORS.light.medium};
	margin: ${({ spacing }) => (spacing ? getSpacing(spacing) : 0)} 0;
`;
