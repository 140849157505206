const FULL_WIDTH = 100;
const IMAGE_HEIGHT = {
	large: 248,
	medium: 136,
	small: 108
};

export const getGridTypeByItemsAmount = totalItems => ({
	1: 'FIRST',
	2: 'SECOND',
	3: 'THIRD',
	4: 'FOURTH',
	5: 'FIFTH'
}[totalItems]);

export const getGridLayoutByType = type => ({
	FIRST: {
		default: {
			width: FULL_WIDTH,
			height: IMAGE_HEIGHT.large
		}
	},
	SECOND: {
		default: {
			width: FULL_WIDTH / 2,
			height: IMAGE_HEIGHT.large
		}
	},
	THIRD: {
		FIRST_ITEM: {
			width: FULL_WIDTH,
			height: IMAGE_HEIGHT.medium
		},
		default: {
			width: FULL_WIDTH / 2,
			height: IMAGE_HEIGHT.small
		}
	},
	FOURTH: {
		FIRST_ITEM: {
			width: FULL_WIDTH,
			height: IMAGE_HEIGHT.medium
		},
		default: {
			width: FULL_WIDTH / 3,
			height: IMAGE_HEIGHT.small
		}
	},
	FIFTH: {
		FIRST_ITEM: {
			width: FULL_WIDTH / 2,
			height: IMAGE_HEIGHT.medium
		},
		SECOND_ITEM: {
			width: FULL_WIDTH / 2,
			height: IMAGE_HEIGHT.medium
		},
		default: {
			width: FULL_WIDTH / 3,
			height: IMAGE_HEIGHT.small
		}
	}
}[type]);

export const getItemLayoutName = totalItems => ({
	1: 'FIRST_ITEM',
	2: 'SECOND_ITEM',
	3: 'THIRD_ITEM',
	4: 'FOURTH_ITEM',
	5: 'FIFTH_ITEM'
}[totalItems]);
