import styled from 'styled-components';

import { Carousel as Slider } from 'react-responsive-carousel';
import { Button, Text, Container } from '..';

export const Carousel = styled(Container)`
	flex-direction: column;
	position: absolute;
    height: 100vh;
    background: white;
`;

Carousel.Text = Text;

Carousel.Header = styled.div`
	padding: 16px;
`;

Carousel.Body = styled.div`
	width: 100%;
	height: 100%;
`;

Carousel.Slider = Slider;

Carousel.Button = Button;

Carousel.ImageContainer = styled.div`
	margin: 0 16px;
`;
