import httpClient from '../utils/httpClient';

export const findSettingsBySlug = async filter => {
	const { data, error } = await httpClient({
		method: 'get',
		url: `/companies/${filter.slug}`
	});

	return {
		data,
		error
	};
};

export const findSettingsByToken = async filter => {
	const { data, error } = await httpClient({
		method: 'get',
		url: `/companies/settings-by-token`,
		headers: {
			authorization: `Bearer ${filter.token}`
		}
	});

	return {
		data,
		error
	};
};

export const findScheduleSettingsBySlug = async filter => {
	const { data, error } = await httpClient({
		method: 'get',
		url: `/companies/${filter.slug}/scheduler-settings`
	});

	return {
		data,
		error
	};
};
