import * as moment from 'moment-timezone';

import React, { useCallback, useMemo, useState } from 'react';

import { DateUtils } from '../../utils';

import Calendar from './Calendar';

function CalendarContainer({
	selectedDate,
	enabledDates,
	variant,
	onChangeDate
}) {
	const [currentWeekDate, setCurrentWeek] = useState(selectedDate);

	const dates = useMemo(() => DateUtils.getWeekDaysFromDate(currentWeekDate), [currentWeekDate])

	const handleChangeWeek = useCallback(action => {
		const momentAction = action === 'next' ? 'add' : 'subtract';

		const newWeek = moment(currentWeekDate)[momentAction](1, 'week');

		setCurrentWeek(newWeek);
	}, [setCurrentWeek, currentWeekDate]);

    return (
		<Calendar
			selectedDate={selectedDate}
			currentWeek={currentWeekDate}
			enabledDates={enabledDates}
			dates={dates}
			variant={variant}
			onChangeDate={onChangeDate}
			onChangeWeek={handleChangeWeek}
		/>
	);
}

export default CalendarContainer;
