export const THEME = {
	radius: {
		small: 4,
		medium: 8,
		large: 16,
		extraLarge: 32
	},
	spacing: {
		extraSmall: 4,
		small: 8,
		medium: 16,
		large: 24,
		extraLarge: 32
	},
	icon: {
		size: {
			small: 12,
			medium: 16,
			large: 20,
			extraLarge: 30
		}
	},
	text: {
		fontFamily: 'Inter',
		type: {
			h1: 48,
			h2: 36,
			h3: 24,
			h4: 20,
			h5: 16,
			h6: 14,
			p: 14,
			small: 12
		},
		weight: {
			light: '300',
			regular: '400',
			medium: '500',
			semiBold: '600',
			bold: '700'
		}
	},
	button:{
		size: {
			small: 40,
			medium: 48
		}
	}
};

export const COLORS = {
	white: '#FFFFFF',
	primary: {
		pure: '#008CFF',
		low: '#EBF5FF',
		medium: '#0069BF',
		high: '#00417A'
	},
	dark: {
		pure: '#26282A',
		low: '#B5B8BA',
		medium: '#767B7F',
		high: '#4E5255'
	},
	light: {
		pure: '#FFFFFF',
		low: '#F2F5F7',
		medium: '#D9E1E8',
		high: '#C0CDD8'
	},
	danger: {
		pure: '#FF0033',
		low: '#FFEBEF',
		medium: '#A30021',
		high: '#7A0018'
	},
	warning: {
		pure: '#FF7700',
		low: '#FFF4EB',
		medium: '#A34C00',
		high: '#7A3900'
	},
	success: {
		pure: '#47CC33',
		low: '#F0FBEF',
		medium: '#2D8321',
		high: '#226218'
	},
	yellow: {
		pure: '#FFD500',
		low: '#FFFCEB',
		medium: '#A38800',
		high: '#7A6600'
	}
};

export const TEXT = THEME.text;

export const ICON = THEME.icon;

export const BUTTON = THEME.button;

export const BORDER_RADIUS = {
	small: `${THEME.radius.small}px`,
	medium: `${THEME.radius.medium}px`,
	large: `${THEME.radius.large}px`,
	extraLarge: `${THEME.radius.extraLarge}px`
};

export const SPACING = {
	extraSmall: `${THEME.spacing.extraSmall}px`,
	small: `${THEME.spacing.small}px`,
	medium: `${THEME.spacing.medium}px`,
	large: `${THEME.spacing.large}px`,
	extraLarge: `${THEME.spacing.extraLarge}px`
};
