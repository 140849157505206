import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useCompanyContext } from '../../contexts/company';
import { NavigationUtils } from '../../utils';

import SelectPaymentMethodForm from './SelectPaymentMethodForm';

const PAYMENT_METHODS_ALLOWED = {
	PARTICULAR: 'PARTICULAR',
	INSURANCE: 'INSURANCE'
};

function SelectPaymentMethodFormContainer() {
	const history = useHistory();
	const { scheduleSettings, form, settings, setInfo } = useCompanyContext();

	const [paymentMethod, setPaymentMethod] = useState(form?.payment_method);
	const [insuranceGroupId, setInsuranceGroupId] = useState(form?.insurance_group?.id);
	const [insuranceId, setInsuranceId] = useState(form?.insurance?.id);
	const insurances = useMemo(() => scheduleSettings?.insurance_plans?.filter(insurance => !insuranceGroupId || insurance.health_insurance_group_id === ~~insuranceGroupId), [insuranceGroupId, scheduleSettings?.insurance_plans]);

	const handleChangePaymentMethod = useCallback(payment => {
		if (payment === PAYMENT_METHODS_ALLOWED.PARTICULAR) {
			setInsuranceId('');
			setInsuranceGroupId('');
		}
	}, [setInsuranceId, setInsuranceGroupId]);

	useEffect(() => {
		handleChangePaymentMethod(paymentMethod);
	}, [paymentMethod, handleChangePaymentMethod])

	const handleNextStepPress = useCallback(() => {
		setInfo(values => ({
			...values,
			form: {
				...values.form,
				payment_method: paymentMethod,
				insurance: insurances?.find(item => item.id === ~~insuranceId)
			}
		}));

		NavigationUtils.navigate(history, '/select-event');
	}, [history, setInfo, insurances, insuranceId, paymentMethod]);

	const handleNavigateHome = useCallback(() => NavigationUtils.goBackHome(history), [history])

	const handleNavigateBackPress = useCallback(() => {
		setInfo(values => ({
			...values,
			form: {
				...values.form,
				payment_method: null,
				insurance: null
			}
		}));

		NavigationUtils.navigate(history, '/select-event-group');
	}, [history, setInfo]);

    return (
		<SelectPaymentMethodForm
			paymentMethodsAllowed={PAYMENT_METHODS_ALLOWED}
			paymentMethod={paymentMethod}
			insuranceGroupId={insuranceGroupId}
			insuranceId={insuranceId}
			insurances={insurances}
			showInsurances={!!scheduleSettings?.insurance_plans.length}
			insuranceGroups={scheduleSettings?.insurance_groups}
			variant={settings.theme?.name}
			onNextStepPress={handleNextStepPress}
			onSelectInsuranceGroup={setInsuranceGroupId}
			onSelectPaymentMethod={setPaymentMethod}
			onSelectInsurance={setInsuranceId}
			handleNavigateBackPress={handleNavigateBackPress}
			handleNavigateHome={handleNavigateHome}
		/>
	);
}

export default SelectPaymentMethodFormContainer;
