import React, { useCallback } from "react";

import { Form as StyledForm } from "./TimegridForm.styles.js";

import { Calendar, Button, Loading, FlatList } from '../../../components';

import SlotItem from '../SlotItem';

function SelectTimegridForm({
	loading,
	selectedSlot,
	listMaxHeight,
	selectedDate,
	enabledDates,
	variant,
	onSelectSlot,
	currentSlotsByUser,
	onSelectDate,
	onSubmitPress,
	handleNavigateBackPress,
	handleNavigateHome,
	withoutSteps,
	submitText
}) {
	const renderSlotItem = useCallback(data => (
		<SlotItem data={data} selectedSlot={selectedSlot} onSelectSlot={onSelectSlot} />
	), [selectedSlot, onSelectSlot]);

	const renderSlotKeyExtractor = useCallback((data, index) => `${index}-${data.user.id}-${selectedDate}`, [selectedDate]);

	if (loading) {
		return <Loading />
	}

	return (
		<StyledForm>
			<StyledForm.Header>
				<StyledForm.Header.BodyContainer>
					<Button variant="tertiary" marginRight={8} onClick={handleNavigateBackPress}>
						<i className="doca-icon doca-icon--large doca-icon__arrow-left" />
					</Button>

					<StyledForm.Header.Title size="large" weight="semibold">
						Data e Horário
					</StyledForm.Header.Title>
				</StyledForm.Header.BodyContainer>

				{handleNavigateHome && (
					<Button variant="tertiary" onClick={handleNavigateHome}>
						<i className="doca-icon doca-icon--large doca-icon__xmark" />
					</Button>
				)}
			</StyledForm.Header>

			<StyledForm.Body>
				{withoutSteps ? null : (
					<div className="doca-mb-4 doca-mt-2">
						<label className="doca-steps__label">
							Passo 5 de 5
						</label>
						<div className="doca-steps__container">
							<div className={`doca-steps doca-mr-1 doca-steps--done doca-steps--${variant}`} />
							<div className={`doca-steps doca-mr-1 doca-steps--done doca-steps--${variant}`} />
							<div className={`doca-steps doca-mr-1 doca-steps--done doca-steps--${variant}`} />
							<div className={`doca-steps doca-mr-1 doca-steps--done doca-steps--${variant}`} />
							<div className={`doca-steps doca-mr-1 doca-steps--current doca-steps--${variant}`} />
						</div>
					</div>
				)}

				<Calendar selectedDate={selectedDate} enabledDates={enabledDates} variant={variant} onChangeDate={onSelectDate} />

				<div className="doca-mt-4">
					{currentSlotsByUser.length ? (
						<FlatList
							data={currentSlotsByUser}
							renderItem={renderSlotItem}
							renderKeyExtractor={renderSlotKeyExtractor}
							maxHeight={listMaxHeight}
						/>
					) : (
						<div className="doca-alert doca-alert--warning">Nenhum horário disponível.</div>
					)}
				</div>
			</StyledForm.Body>

			<Button marginTop={16} variant={variant} className="doca-mt-4" block size="large" disabled={!selectedSlot} onClick={onSubmitPress}>{submitText}</Button>
		</StyledForm>
	);
}

export default SelectTimegridForm;
