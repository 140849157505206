import styled from 'styled-components';

import DateItem from '../../views/_shared/DateItem';

import { Button, Text } from '../../components';

export const Calendar = styled.div`
	display: flex;
	flex-direction: column;
`;

Calendar.Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

Calendar.Header.Month = Text;

Calendar.Button = Button;

Calendar.Header.ActionContainer = styled.div`
	display: flex;
	flex: 1;
	justify-content: flex-end;
`;

Calendar.DateWeekList = styled.div`
	display: flex;
	align-items: center;
`;

Calendar.DateItemContainer = styled.div`
	margin-right: ${({ isLastChild }) => isLastChild ? '0' : '8px'};
	display: flex;
	flex: 1;
`;

Calendar.DateItem = DateItem;
