import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { toast } from 'react-toastify';

import { useParams, useHistory } from 'react-router-dom';

import { DateUtils, NavigationUtils, WindowDimensionsUtils } from '../../utils';

import { list } from '../../processes/calendar';
import { updateAttendance, findAttendanceByToken } from '../../processes/attendance';
import { findSettingsByToken } from '../../processes/company';

import SelectTimegridForm from '../_shared/TimegridForm';

import moment from 'moment';

const footerPlusHeaderHeight = 344;

function SelectTimegridFormContainer() {
	const history = useHistory();
	const params = useParams();
	const dimensions = WindowDimensionsUtils.useWindowDimensions();

	const [settings, setSettings] = useState(null);

	const [loading, setLoading] = useState(true);
	const [slotsByDate, setSlotsByDate] = useState([]);
	const [enabledDates, setEnabledDates] = useState([]);
	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedSlot, setSelectedSlot] = useState(null);
	const [currentSlotsByUser, setCurrentSlotsByUser] = useState([]);

	const listMaxHeight = useMemo(() => !dimensions?.height ? 300 : dimensions?.height - footerPlusHeaderHeight, [dimensions?.height])

	const getCalendarAndSlots = useCallback(async attendanceData => {
		const filter = {
			event_id: attendanceData?.event?.id,
			place_id: attendanceData?.place?.id,
			insurance_id: attendanceData?.insurance?.id,
			patient_id: attendanceData?.patient?.id
		};

		const { data, error } = await list(filter);

		if (error) {
			toast.error('Não foi possível buscar os horários disponíveis, tente novamente mais tarde.');
		} else {
			const { datesEnabled, currentDateSlotsByUser, closestSelectedDate } = data.reduce((acc, info) => {
				if (DateUtils.isToday(info.date)) {
					return acc;
				}

				if (!acc.closestSelectedDate && info.status === 'AVAILABLE' && moment(attendanceData.start_date).isSame(info.date, 'day')) {
					acc.closestSelectedDate = info.date;

					acc.currentDateSlotsByUser = info.slotsByUser;
				}

				if (info.status === 'AVAILABLE') {
					acc.datesEnabled.push(info.date);
				}

				return acc;
			}, {
				datesEnabled: [],
				currentDateSlotsByUser: {},
				closestSelectedDate: null
			});

			setSlotsByDate(data);
			setEnabledDates(datesEnabled);
			setCurrentSlotsByUser(currentDateSlotsByUser);
			setSelectedDate(closestSelectedDate);
		}

		setLoading(false);
	}, [])

	const init = useCallback(async () => {
		const [{ data: attendanceData }, { data: settingsData }] = await Promise.all([
			findAttendanceByToken(params),
			findSettingsByToken(params)
		]);

		if (!attendanceData || !settingsData) {
			toast.error('Não foi possível buscar as informações, tente novamente mais tarde.');
		} else {
			setSettings(settingsData);

			localStorage.setItem('token', settingsData.token);

			getCalendarAndSlots(attendanceData);
		}
	}, [params, getCalendarAndSlots]);

	useEffect(() => {
		init();
	}, [init]);

	const handleSubmit = useCallback(async () => {
		const start = moment(selectedSlot.start, 'HH:mm');
		const end = moment(selectedSlot.end, 'HH:mm');

		const attendanceData = {
			start_date: moment(selectedDate).hour(start.format('HH')).minute(start.format('mm')),
			end_date: moment(selectedDate).hour(end.format('HH')).minute(end.format('mm')),
			user_id: selectedSlot.user_id,
			token: params.token
		};

		setLoading(true);

		const { error } = await updateAttendance(attendanceData);

		setLoading(false);

		if (error) {
			toast.error('Algo de errado aconteceu ao atualizar o agendamento, tente novamente em alguns instantes.');
		} else {
			toast.success('Agendamento atualizado com sucesso.');

			NavigationUtils.navigate(history, `/scheduled-succesfully?token=${params.token}`);
		}
	}, [params.token, history, selectedSlot, selectedDate]);

	const handleSelectDate = useCallback(date => {
		setLoading(true);

		setSelectedDate(date);

		const dateInfoSlots = slotsByDate.find(item => item.date === date)

		setCurrentSlotsByUser(dateInfoSlots?.slotsByUser || []);

		setLoading(false);
	}, [setLoading, setCurrentSlotsByUser, slotsByDate, setSelectedDate]);

	const handleNavigateBackPress = useCallback(() => {
		return NavigationUtils.navigate(history, `/scheduled-succesfully?token=${params?.token}`);
	}, [history, params?.token]);

    return (
		<SelectTimegridForm
			loading={loading}
			selectedSlot={selectedSlot}
			listMaxHeight={listMaxHeight}
			selectedDate={selectedDate}
			variant={settings?.theme?.name}
			enabledDates={enabledDates}
			onSelectDate={handleSelectDate}
			currentSlotsByUser={currentSlotsByUser}
			onSubmitPress={handleSubmit}
			onSelectSlot={setSelectedSlot}
			withoutSteps
			handleNavigateBackPress={handleNavigateBackPress}
			submitText="Atualizar agendamento"
		/>
	);
}

export default SelectTimegridFormContainer;
