import styled from 'styled-components';

import { COLORS, BORDER_RADIUS } from '../../../constants/theme';

export const GridItem = styled.div`
	width: 100%;
	border-radius: ${BORDER_RADIUS.medium};
	align-items: center;
	justify-content: center;
	background-color: ${({ overlay }) => overlay ? COLORS.dark.low : COLORS.light.low};
`;

GridItem.Image = styled.img`
	border-radius: ${BORDER_RADIUS.medium};
	width: 100%;
	background-color: white;
	height: ${({ imageHeight }) => imageHeight && imageHeight}px !important;
	opacity: ${({ overlay }) => (overlay ? 0.7 : 1)};
`;

GridItem.PlaceholderContainer = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;

GridItem.Text = styled.span`
	color: ${COLORS.white};
`;
