import React, { useCallback, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useCompanyContext } from '../../contexts/company';
import { NavigationUtils } from '../../utils';

import SelectEventGroupForm from './SelectEventGroupForm';

function SelectEventGroupFormContainer() {
	const { scheduleSettings, form, settings, setInfo } = useCompanyContext();
	const [group, setGroup] = useState(form?.event_group);
	const history = useHistory();

	const handleNextStepPress = useCallback(() => {
		setInfo(values => ({
			...values,
			form: {
				...values.form,
				event_group: group
			}
		}));

		NavigationUtils.navigate(history, '/select-payment-method');
	}, [history, setInfo, group]);

	const handleNavigateBackPress = useCallback(() => {
		if (group) {
			setInfo(values => ({
				...values,
				form: {
					...values.form,
					event_group: null
				}
			}));
		}

		NavigationUtils.goBackHome(history);
	}, [history, group, setInfo]);

    return (
		<SelectEventGroupForm
			selectedGroup={group}
			groups={scheduleSettings?.event_groups}
			variant={settings.theme?.name}
			onNextStepPress={handleNextStepPress}
			onSelectGroup={setGroup}
			handleNavigateBackPress={handleNavigateBackPress}
		/>
	);
}

export default SelectEventGroupFormContainer;
