import React, { memo } from 'react';

import { Loading as StyledLoading } from './Loading.styles';

const ANIMATION_BY_VARIANT = {
	normal: require('../../assets/animations/amigo.json'),
	white: require('../../assets/animations/amigo-white.json')
};

const Loading = ({ variant = 'normal', flexGrow }) => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: ANIMATION_BY_VARIANT[variant],
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		}
	};

	return (
		<StyledLoading flexGrow={flexGrow}>
			<StyledLoading.Animation
				options={defaultOptions}
				width="70px"
				height="70px"
				source={ANIMATION_BY_VARIANT[variant]}
			/>
		</StyledLoading>
	)
};

export default memo(Loading);
