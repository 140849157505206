import moment from 'moment-timezone';

import 'moment/locale/pt-br';

moment.locale('pt-br');

moment.fn.isToday = function () {
	const today = moment();

	return this.isSame(today, 'day');
};

moment.fn.isYesterday = function () {
	const yesterday = moment().subtract(1, 'day');

	return this.isSame(yesterday, 'day');
};

const DEFAULT_FORMAT = 'DD/MM/YYYY';

export const getDateInstance = date => moment(date);

export const now = () => moment();

export const toISOString = date => moment(date).toISOString();

export const fromNow = date => moment().to(date);

export const formatDate = (date, targetFormat = DEFAULT_FORMAT, sourceFormat) => {
	return date ? moment(date, sourceFormat).format(targetFormat) : '';
};

export const isToday = date => moment(date).isToday();

export const getWeekDaysFromDate = date => {
	const days = [];

	const startDate = moment(date).startOf('isoWeek');

	while (days.length < 7) {
		days.push(formatDate(startDate, 'YYYY-MM-DD'));

		startDate.add(1, 'day');
	}

	return days;
}

export const getDateLabel = (date, format = DEFAULT_FORMAT) => {
	const dateInstance = moment(date);

	return dateInstance.isToday() ? 'Hoje' : dateInstance.format(format);
};

export const isValid = (date, format, strict) => moment(date, format, strict).isValid();

export const isSame = (firstDate, secondDate, unit) => moment(firstDate).isSame(secondDate, unit);

export const isBefore = (firstDate, secondDate, unit) => moment(firstDate).isBefore(secondDate, unit);

export const isAfter = (firstDate, secondDate, unit) => moment(firstDate).isAfter(moment(secondDate), unit);

export const diff = (firstDate, secondDate, unit = 'seconds') => moment(firstDate).diff(moment(secondDate), unit);
