import styled from 'styled-components';

import { Text } from '../../components';

export const Form = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 16px;
`;

Form.Header = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

Form.Header.BodyContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

Form.Body = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

Form.Header.Title = Text;
