import React from "react";

import { Form as StyledForm } from "./SelectPaymentMethodForm.styles.js";

import { Button, Select } from '../../components';

const SelectPaymentMethodForm = ({
	paymentMethodsAllowed: PAYMENT_METHODS_ALLOWED,
	insurances,
	showInsurances,
	insuranceGroups,
	insuranceGroupId,
	insuranceId,
	variant,
	onSelectInsurance,
	onSelectInsuranceGroup,
	paymentMethod,
	onNextStepPress,
	onSelectPaymentMethod,
	handleNavigateBackPress,
	handleNavigateHome
}) => (
	<StyledForm>
		<StyledForm.Header>
			<StyledForm.Header.BodyContainer>
				<Button variant="tertiary" marginRight={8} onClick={handleNavigateBackPress}>
					<i className="doca-icon doca-icon--large doca-icon__arrow-left" />
				</Button>

				<StyledForm.Header.Title size="large" weight="semibold">
					Forma de pagamento
				</StyledForm.Header.Title>
			</StyledForm.Header.BodyContainer>

			<Button variant="tertiary" onClick={handleNavigateHome}>
				<i className="doca-icon doca-icon--large doca-icon__xmark" />
			</Button>
		</StyledForm.Header>

		<StyledForm.Body>
			<div className="doca-mb-4 doca-mt-2">
				<label className="doca-steps__label">Passo 2 de 5</label>
				<div className="doca-steps__container">
					<div className={`doca-steps doca-mr-1 doca-steps--done doca-steps--${variant}`} />
					<div className={`doca-steps doca-mr-1 doca-steps--current doca-steps--${variant}`} />
					<div className={`doca-steps doca-mr-1 doca-steps--${variant}`} />
					<div className={`doca-steps doca-mr-1 doca-steps--${variant}`} />
					<div className={`doca-steps doca-mr-1 doca-steps--${variant}`} />
				</div>
			</div>

			<StyledForm.FlexRow className="doca-shortcut__container">
				<button
					className={`doca-shortcut w-100 doca-mr-4 ${paymentMethod === PAYMENT_METHODS_ALLOWED.INSURANCE ? `doca-shortcut--${variant}` : ''}`}
					onClick={() => onSelectPaymentMethod(PAYMENT_METHODS_ALLOWED.INSURANCE)}
					hidden={!showInsurances}
				>
					<div>
						<i className="doca-icon doca-icon--large doca-icon__hospital" />

						<p className="doca-shortcut__title">Convênio</p>
					</div>
				</button>

				<button
					className={`doca-shortcut w-100 ${paymentMethod === PAYMENT_METHODS_ALLOWED.PARTICULAR ? `doca-shortcut--${variant}` : ''}`}
					onClick={() => onSelectPaymentMethod(PAYMENT_METHODS_ALLOWED.PARTICULAR)}
				>
					<div>
						<i className="doca-icon doca-icon--large doca-icon__credit-card" />

						<p className="doca-shortcut__title">Particular</p>
					</div>
				</button>
			</StyledForm.FlexRow>

			<StyledForm.FlexRow direction="column" className="doca-mt-4">
				{paymentMethod === PAYMENT_METHODS_ALLOWED.INSURANCE ? (
					<div>
						<StyledForm.Header.Title size="large" weight="semibold">
							Selecione seu convênio
						</StyledForm.Header.Title>

						{insuranceGroups?.length ? (
							<Select
								defaultValue={insuranceGroupId}
								label="Escolha seu convênio"
								placeholder="Selecione"
								size="large"
								required
								onChange={onSelectInsuranceGroup}
							>
								<option value="">Selecione</option>

								{insuranceGroups.map((insuranceGroup) => (
									<option key={insuranceGroup.id} value={insuranceGroup.id}>
										{insuranceGroup.name}
									</option>
								))}
							</Select>
						) : null}

						<Select defaultValue={insuranceId} label="Escolha seu plano" placeholder="Selecione" size="large" required onChange={onSelectInsurance}>
							<option value="">Selecione</option>

							{insurances?.map((insurance) => (
								<option key={insurance.id} value={insurance.id}>
									{insurance.name}
								</option>
							))}
						</Select>
					</div>
				) : null}
			</StyledForm.FlexRow>
		</StyledForm.Body>

		<Button
			marginTop={24}
			variant={variant}
			className="doca-mt-4"
			block
			size="large"
			disabled={!paymentMethod || (paymentMethod === PAYMENT_METHODS_ALLOWED.INSURANCE && !insuranceId)}
			onClick={onNextStepPress}
		>
			Próximo
			<i className="doca-icon doca-icon--large doca-icon__arrow-right"></i>
		</Button>
	</StyledForm>
);

export default SelectPaymentMethodForm;
