import styled from 'styled-components';

import { Text, Separator, Button } from '../../components';

export const ScheduledPage = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	background: #f0f0f0;
`;

ScheduledPage.CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 0 16px 16px;
`;

ScheduledPage.CardContainer.Title = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	color: #26282A;
	margin-bottom: 16px;
`;

ScheduledPage.Body = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

ScheduledPage.FlexContainer = styled.div`
	display: flex;
	flex-direction: row;
`

ScheduledPage.Text = Text;
ScheduledPage.Separator = Separator;
ScheduledPage.Button = Button;

ScheduledPage.Header = styled.header`
	display: flex;
	flex-direction: column;
	background: white;
`;

ScheduledPage.BackgroundImage = styled.img`
	width: 100%;
	object-fit: cover;
	height: 140px !important;
`;

ScheduledPage.CompanyInfoRow = styled.div`
	display: flex;
	flex-direction: column;
	padding: 16px;
`;

ScheduledPage.CompanyInfoRow.AvatarContainer = styled.div`
	position: relative;
	margin-bottom: 18px;
`;

ScheduledPage.CompanyInfoRow.Avatar = styled.img`
	object-fit: cover;
	width: 72px !important;
	height: 72px !important;
	margin-bottom: 18px;
	position: absolute;
	bottom: -30px;
`;

ScheduledPage.CompanyInfoRow.Content = styled.div`
	display: flex;
	flex-direction: column;
`;

ScheduledPage.CompanyInfoRow.Content.Title = styled.div`
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	color: #26282A;
`;

ScheduledPage.CompanyInfoRow.Content.Address = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #4E5255;
`;

ScheduledPage.CompanyInfoRow.Content.Specialty = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #4E5255;
`;

