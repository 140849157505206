import React, { useCallback, useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { useHistory } from 'react-router-dom';

import { useCompanyContext } from '../../contexts/company';
import { NavigationUtils } from '../../utils';

import SelectEventForm from './SelectEventForm';

import { list } from '../../processes/agenda-event';

function SelectEventFormContainer() {
	const { scheduleSettings, form, settings, setInfo } = useCompanyContext();
	const [loading, setLoading] = useState(false);
	const [events, setEvents] = useState([]);

	const getEvents = useCallback(async() => {
		setLoading(true);

		const filter = {
			insurance_id: form?.insurance?.id,
			scheduler_event_group_id: form?.event_group?.id
		};

		if (!filter.scheduler_event_group_id) {
			return;
		}

		const { data, error } = await list(filter);

		setLoading(false);

		if (error) {
			toast.error('Não foi possível buscar os tipos de atendimentos, tente novamente mais tarde.');
			
			return;
		}
		
		setEvents(data);
	}, [form?.insurance?.id, form?.event_group?.id])

	const [eventId, setEventId] = useState(form?.event?.id || '');

	const history = useHistory();

	const handleNextStepPress = useCallback(() => {
		setInfo(values => ({
			...values,
			form: {
				...values.form,
				event: scheduleSettings.events.find(item => item.id === ~~eventId)
			}
		}));

		NavigationUtils.navigate(history, '/select-place');
	}, [history, setInfo, eventId, scheduleSettings.events]);

	const handleNavigateHome = useCallback(() => NavigationUtils.goBackHome(history), [history])

	const handleSelectEvent = useCallback(eventId => setEventId(eventId), [])

	const handleNavigateBackPress = useCallback(() => {
		if (eventId) {
			setInfo(values => ({
				...values,
				form: {
					...values.form,
					event: null
				}
			}));
		}

		NavigationUtils.navigate(history, '/select-payment-method');
	}, [history, eventId, setInfo]);

	useEffect(() => {
		getEvents();
	}, [getEvents]);

    return (
		<SelectEventForm
			selectedEventId={eventId}
			events={events}
			variant={settings.theme?.name}
			onNextStepPress={handleNextStepPress}
			onSelectEvent={handleSelectEvent}
			handleNavigateBackPress={handleNavigateBackPress}
			handleNavigateHome={handleNavigateHome}
			loading={loading}
		/>
	);
}

export default SelectEventFormContainer;
