import React, { memo } from 'react';

import { EmptyState as StyledEmptyState } from './EmptyState.styles';

const EmptyState = ({ icon, title, description }) => {
	return (
		<StyledEmptyState>
			<div className="doca-empty-state">
				<i className={`doca-icon doca-empty-state__icon doca-icon__${icon}`} />

				<h1 className="doca-empty-state__title">
					{title}
				</h1>

				{description && (
					<h2 className="doca-empty-state__description">
						{description}
					</h2>
				)}
			</div>
		</StyledEmptyState>
	)
};

export default memo(EmptyState);
