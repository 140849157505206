import { getClasses } from '../../utils/styler';

const Text = ({
	variant,
	size,
	weight,
	children,
	style,
	icon,
	...props
}) => (
	<p style={style} className={getClasses({ ...props }, {
		'doca-text': true,
		[`doca-text--${variant}`]: variant,
		[`doca-text--${weight}`]: weight,
		[`doca-text--${size}`]: !!size,
	})}>
		{icon && (
			<i className={`doca-icon doca-icon--regular doca-icon__${icon} doca-mr-2`}></i>
		)}

		{children}
	</p>
);

export default Text;
