import React from 'react';

import { GridItem } from './GalleryGridItem.styles';

const GalleryGridItem = ({
	uri,
	blur,
	itemHeight,
	itemWidth,
	overlay,
	children,
	index,
	onPress
}) => {
	const imageWidth = itemWidth || 0;

	return (
		<GridItem
			overlay={overlay}
			onClick={() => onPress && onPress(index)}
		>
			<GridItem.Image
				blur={blur}
				imageHeight={itemHeight}
				imageWidth={imageWidth}
				overlay={overlay}
				src={uri}
				href={uri}
			/>
			{children}
		</GridItem>
	);
};

export default GalleryGridItem;
