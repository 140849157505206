import { getClasses } from '../../utils/styler';

const Select = ({ size, disabled, required, defaultValue, label, feedback, variant, children, onChange, ...props }) => (
	<div className="doca-form__field doca-mt-4">
		{label && <label className="doca-form__label">
			{label}
		</label>}
		<div className="">
			<select
				defaultValue={defaultValue}
				className={getClasses({ ...props }, {
					'doca-form-control': true,
					[`doca-form-control--${variant}`]: variant,
					[`doca-form-control--${size}`]: !!size,
				})}
				onChange={({ target }) => onChange && onChange(target.value)}
			>
				{children}
			</select>
		</div>
		{feedback && <span className={getClasses({ ...props }, {
			'doca-form__feedback': true,
			[`doca-form__feedback--${variant}`]: variant,
		})}>
			{feedback}
		</span>}
	</div>
);

export default Select;
