import React from "react";
import { Form as StyledForm } from "./SelectEventForm.styles.js";
import { Button, Select, Loading, Text } from '../../components';

function SelectEventForm({
	events,
	selectedEventId,
	onSelectEvent,
	onNextStepPress,
	handleNavigateBackPress,
	variant,
	handleNavigateHome,
	loading
}) {
	if (loading) {
		return <Loading />;
	}

	return (
		<StyledForm>
			<StyledForm.Header>
				<StyledForm.Header.BodyContainer>
					<Button variant="tertiary" marginRight={8} onClick={handleNavigateBackPress}>
						<i className="doca-icon doca-icon--large doca-icon__arrow-left" />
					</Button>

					<StyledForm.Header.Title size="large" weight="semibold">
						Tipo de atendimento
					</StyledForm.Header.Title>
				</StyledForm.Header.BodyContainer>

				<Button variant="tertiary" onClick={handleNavigateHome}>
					<i className="doca-icon doca-icon--large doca-icon__xmark" />
				</Button>
			</StyledForm.Header>

			<StyledForm.Body>
				<div className="doca-mt-2">
					<label className="doca-steps__label">
						Passo 3 de 5
					</label>

					<div className="doca-steps__container">
						<div className={`doca-steps doca-mr-1 doca-steps--done doca-steps--${variant}`} />
						<div className={`doca-steps doca-mr-1 doca-steps--done doca-steps--${variant}`} />
						<div className={`doca-steps doca-mr-1 doca-steps--current doca-steps--${variant}`} />
						<div className={`doca-steps doca-mr-1 doca-steps--${variant}`} />
						<div className={`doca-steps doca-mr-1 doca-steps--${variant}`} />
					</div>
				</div>

				{events.length > 0 ? (
					<Select
						defaultValue={selectedEventId}
						label="Escolha o tipo de atendimento"
						placeholder="Selecione"
						size="large"
						required
						onChange={onSelectEvent}
					>
						<option value="">Selecione</option>
						{events.map(event => (
							<option key={event.id} value={event.id}>
								{event.name}
							</option>
						))}
					</Select>
				) : (
					<Text marginTop={16} >Não existem tipos de atendimento para essa modalidade e forma de pagamento.</Text>
				)}
			</StyledForm.Body>

			<Button
				marginTop={24}
				variant={variant}
				className="doca-mt-4"
				block
				size="large"
				disabled={!selectedEventId}
				onClick={onNextStepPress}
			>
				Próximo
				<i className="doca-icon doca-icon--large doca-icon__arrow-right"></i>
			</Button>
		</StyledForm>
	);
}

export default SelectEventForm;
