import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { toast } from 'react-toastify';

import { useHistory } from 'react-router-dom';

import { useCompanyContext } from '../../contexts/company';
import { DateUtils, NavigationUtils, WindowDimensionsUtils } from '../../utils';

import { list } from '../../processes/calendar';
import { createAttendance } from '../../processes/attendance';

import SelectTimegridForm from '../_shared/TimegridForm';
import moment from 'moment';

const footerPlusHeaderHeight = 344;

function SelectTimegridFormContainer() {
	const history = useHistory();
	const { form, settings, setInfo } = useCompanyContext();
	const dimensions = WindowDimensionsUtils.useWindowDimensions();

	const [loading, setLoading] = useState(false);
	const [slotsByDate, setSlotsByDate] = useState([]);
	const [enabledDates, setEnabledDates] = useState([]);
	const [selectedDate, setSelectedDate] = useState([]);
	const [selectedSlot, setSelectedSlot] = useState(form?.slot);
	const [currentSlotsByUser, setCurrentSlotsByUser] = useState([]);

	const listMaxHeight = useMemo(() => !dimensions?.height ? 300 : dimensions?.height - footerPlusHeaderHeight, [dimensions?.height])

	const getCalendarAndSlots = useCallback(async() => {
		const filter = {
			event_id: form.event?.id,
			place_id: form.place?.id,
			insurance_id: form.insurance?.id,
			patient_id: form?.patient?.id
		};

		if (!filter.event_id || !filter.patient_id || !filter.place_id) {
			return;
		}

		setLoading(true);

		const { data, error } = await list(filter);

		if (error) {
			toast.error('Não foi possível buscar os horários disponíveis, tente novamente mais tarde.');
		}

		let { datesEnabled, currentDateSlotsByUser, closestSelectedDate } = (data || []).reduce(
			(acc, info) => {
				if (DateUtils.isToday(info.date)) {
					return acc;
				}

				if (!acc.closestSelectedDate && info.status === 'AVAILABLE') {
					acc.closestSelectedDate = info.date;

					acc.currentDateSlotsByUser = info.slotsByUser;
				}

				if (info.status === 'AVAILABLE') {
					acc.datesEnabled.push(info.date);
				}

				return acc;
			},
			{
				datesEnabled: [],
				currentDateSlotsByUser: {},
				closestSelectedDate: null
			}
		);

		if (!closestSelectedDate) {
			closestSelectedDate = moment().format('YYYY-MM-DD');
		}

		setSlotsByDate(data);
		setEnabledDates(datesEnabled);
		setCurrentSlotsByUser(currentDateSlotsByUser);
		setSelectedDate(closestSelectedDate);

		setLoading(false);
	}, [form.event?.id, form.place?.id, form.patient?.id, form.insurance?.id])

	useEffect(() => {
		getCalendarAndSlots();
	}, [getCalendarAndSlots]);

	const handleSubmit = useCallback(async () => {
		const start = moment(selectedSlot.start, 'HH:mm');
		const end = moment(selectedSlot.end, 'HH:mm');

		const attendanceData = {
			event_id: form.event?.id,
			place_id: form.place?.id,
			patient_id: form.patient?.id,
			insurance_id: form.insurance?.id,
			start_date: moment(selectedDate).hour(start.format('HH')).minute(start.format('mm')),
			end_date: moment(selectedDate).hour(end.format('HH')).minute(end.format('mm')),
			user_id: selectedSlot.user_id
		};

		setLoading(true);

		const { data, error } = await createAttendance(attendanceData);

		setLoading(false);

		if (error) {
			toast.error('Algo de errado aconteceu ao criar o agendamento, tente novamente em alguns instantes.');
		} else {
			setInfo(values => ({
				...values,
				form: {
					...values.form,
					slot: selectedSlot,
					start_date: attendanceData.start_date,
					end_date: attendanceData.end_date
				},
				externalPagesToken: data.external_pages_token
			}));

			toast.success('Agendamento criado com sucesso.');

			NavigationUtils.navigate(history, '/scheduled-succesfully');
		}
	}, [history, setInfo, selectedSlot, form.event?.id, form.place?.id, form.patient?.id, form.insurance?.id, selectedDate]);

	const handleNavigateHome = useCallback(() => NavigationUtils.goBackHome(history), [history])

	const handleSelectDate = useCallback(date => {
		setLoading(true);

		setSelectedDate(date);

		const dateInfoSlots = slotsByDate.find(item => item.date === date)

		setCurrentSlotsByUser(dateInfoSlots?.slotsByUser || []);

		setLoading(false);
	}, [setLoading, setCurrentSlotsByUser, slotsByDate, setSelectedDate]);

	const handleNavigateBackPress = useCallback(() => {
		setInfo(values => ({
			...values,
			form: {
				...values.form,
				slot: null,
				start_date: null,
				end_date: null
			}
		}));

		return NavigationUtils.navigate(history, '/select-place');
	}, [history, setInfo]);

    return (
		<SelectTimegridForm
			loading={loading}
			selectedSlot={selectedSlot}
			listMaxHeight={listMaxHeight}
			selectedDate={selectedDate}
			variant={settings.theme?.name}
			enabledDates={enabledDates}
			onSelectDate={handleSelectDate}
			currentSlotsByUser={currentSlotsByUser}
			onSubmitPress={handleSubmit}
			onSelectSlot={setSelectedSlot}
			handleNavigateBackPress={handleNavigateBackPress}
			handleNavigateHome={handleNavigateHome}
			submitText="Concluir o agendamento"
		/>
	);
}

export default SelectTimegridFormContainer;
