import React from "react";

import { Form as StyledForm } from "./SelectPlaceForm.styles.js";

import { Button, Loading, Select } from '../../components';

import { PlaceItem } from '../../views';

function SelectPlaceForm({
	loading,
	places,
	selectedPlaceId,
	selectedPlace,
	variant,
	onSelectPlace,
	onNextStepPress,
	handleNavigateBackPress,
	handleNavigateHome
}) {
	if (loading) {
		return <Loading />
	}

	return (
		<StyledForm>
			<StyledForm.Header>
				<StyledForm.Header.BodyContainer>
					<Button variant="tertiary" marginRight={8} onClick={handleNavigateBackPress}>
						<i className="doca-icon doca-icon--large doca-icon__arrow-left" />
					</Button>

					<StyledForm.Header.Title size="large" weight="semibold">
						Unidade
					</StyledForm.Header.Title>
				</StyledForm.Header.BodyContainer>

				<Button variant="tertiary" onClick={handleNavigateHome}>
					<i className="doca-icon doca-icon--large doca-icon__xmark" />
				</Button>
			</StyledForm.Header>

			<StyledForm.Body>
				<div className="doca-mt-2">
					<label className="doca-steps__label">
						Passo 4 de 5
					</label>
					<div className="doca-steps__container">
						<div className={`doca-steps doca-mr-1 doca-steps--done doca-steps--${variant}`} />
						<div className={`doca-steps doca-mr-1 doca-steps--done doca-steps--${variant}`} />
						<div className={`doca-steps doca-mr-1 doca-steps--done doca-steps--${variant}`} />
						<div className={`doca-steps doca-mr-1 doca-steps--current doca-steps--${variant}`} />
						<div className={`doca-steps doca-mr-1 doca-steps--${variant}`} />
					</div>
				</div>

				<div className="doca-mb-4">
					<Select
						defaultValue={selectedPlaceId}
						label="Escolha a unidade"
						placeholder="Selecione"
						size="large"
						required
						onChange={onSelectPlace}
					>
						<option value="">Selecione</option>

						{places?.map(event => (
							<option
								key={event.id}
								value={event.id}
							>
								{event.name}
							</option>
						))}
					</Select>
				</div>

				{selectedPlace && (
					<PlaceItem place={selectedPlace} titleVariation="gray" />
				)}
			</StyledForm.Body>

			<Button marginTop={24} variant={variant} className="doca-mt-4" block size="large" disabled={!selectedPlaceId} onClick={onNextStepPress}>
				Próximo

				<i className="doca-icon doca-icon--large doca-icon__arrow-right"></i>
			</Button>
		</StyledForm>
	);
}

export default SelectPlaceForm;
