import React from "react";

import { Form as StyledForm } from "./SelectEventGroupForm.styles.js";

import { Button } from '../../components';

function SelectEventGroupForm({
	groups,
	selectedGroup,
	variant,
	onSelectGroup,
	onNextStepPress,
	handleNavigateBackPress
}) {
	return (
		<StyledForm>
			<StyledForm.Header>
				<StyledForm.Header.BodyContainer>
					<StyledForm.Header.Title size="large" weight="semibold">
						Escolha o atendimento
					</StyledForm.Header.Title>
				</StyledForm.Header.BodyContainer>

				<Button variant="tertiary" onClick={handleNavigateBackPress}>
          			<i className="doca-icon doca-icon--large doca-icon__xmark" />
        		</Button>
			</StyledForm.Header>

			<StyledForm.Body>
				<div className="doca-mb-4 doca-mt-2">
					<label className="doca-steps__label">
						Passo 1 de 5
					</label>

					<div className="doca-steps__container">
						<div className={`doca-steps doca-mr-1 doca-steps--current doca-steps--${variant}`} />
						<div className={`doca-steps doca-mr-1 doca-steps--${variant}`} />
						<div className={`doca-steps doca-mr-1 doca-steps--${variant}`} />
						<div className={`doca-steps doca-mr-1 doca-steps--${variant}`} />
						<div className={`doca-steps doca-mr-1 doca-steps--${variant}`} />
					</div>
				</div>

				<div className="doca-shortcut__container d-block">
					{groups?.ATTENDANCE ? (
						<button className={`doca-shortcut w-100 ${selectedGroup?.id === groups.ATTENDANCE?.id ? `doca-shortcut--${variant}` : ''}`} onClick={() => onSelectGroup(groups.ATTENDANCE)}>
							<div>
								<i className="doca-icon doca-icon--large doca-icon__location-dot" />

								<p className="doca-shortcut__title">
									Atendimento presencial
								</p>
							</div>
						</button>
					) : null}

					{groups?.TELEMEDICINE ? (
						<button className={`doca-shortcut w-100 ${selectedGroup?.id === groups.TELEMEDICINE?.id ? `doca-shortcut--${variant}` : ''}`} onClick={() => onSelectGroup(groups.TELEMEDICINE)}>
							<div>
								<i className="doca-icon doca-icon--large doca-icon__video" />

								<p className="doca-shortcut__title">
									Atendimento teleatendimento
								</p>
							</div>
						</button>
					) : null}

					{groups?.COMEBACK ? (
						<button className={`doca-shortcut w-100 ${selectedGroup?.id === groups.COMEBACK?.id ? `doca-shortcut--${variant}` : ''}`} onClick={() => onSelectGroup(groups.COMEBACK)}>
							<div>
								<i className="doca-icon doca-icon--large doca-icon__rotate-left" />

								<p className="doca-shortcut__title">
									Atendimento de retorno
								</p>
							</div>
						</button>
					) : null}
				</div>
			</StyledForm.Body>

			<Button marginTop={24} variant={variant} className="doca-mt-4" block size="large" disabled={!selectedGroup} onClick={onNextStepPress}>
				Próximo

				<i className="doca-icon doca-icon--large doca-icon__arrow-right"></i>
			</Button>
		</StyledForm>
	);
}

export default SelectEventGroupForm;
