export const formatCpf = (cpf = '') =>
	cpf
		.replace(/\D/g, '')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d{1,2})/, '$1-$2')
		.replace(/(-\d{2})\d+?$/, '$1');

export const formatDate = (date = '') =>
	date
		.replace(/\D/g, '')
		.replace(/(\d{2})(\d)/, '$1/$2')
		.replace(/(\d{2})(\d)/, '$1/$2')
		.replace(/(\d{4})(\d{1,2})/, '$1');

export const formatMoney = value => value?.toFixed(2).replace('.', ',');

export const formatPhone = (phone = '') => {
	let phoneNumber = phone.replace(/\D/g, '');
	const match = phoneNumber.match(/^(\d{1,2})(\d{0,5})(\d{0,4})$/);

	if (match) {
		phoneNumber = `(${match[1]}${match[2] ? ') ' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}`;
	}

	return phoneNumber;
};

export const formatCnpj = cnpj => {
	if (!cnpj) {
		return cnpj;
	}

	return cnpj.replace(/\D/g, '')
		.replace(/^(\d{2})(\d)/, '$1.$2')
		.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
		.replace(/\.(\d{3})(\d)/, '.$1/$2')
		.replace(/(\d{4})(\d)/, '$1-$2');
};

export const formatZipcode = zipcode => {
	if (!zipcode) {
		return zipcode;
	}

	return zipcode.replace(/\D/g, '')
		.replace(/(\d{5})(\d)/, '$1-$2')
		.replace(/(-\d{3})\d+?$/, '$1');
};

export const clearMask = value => {
	if (!value) {
		return '';
	}

	return value.replace(/\D/g, '');
};
