import React, { useCallback, useMemo, useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { useQuery } from '../../hooks';
import { GoogleUtils, NavigationUtils } from '../../utils';
import { useCompanyContext } from '../../contexts/company';
import { cancelAttendance, findAttendanceByToken } from '../../processes/attendance';

import ScheduledSucessfully from './ScheduledSucessfully';

function ScheduledSucessfullyContainer() {
	const query = useQuery();

	const history = useHistory();

	const { externalPagesToken, form, settings } = useCompanyContext();

	const externalToken = useMemo(() => query.get('token') || externalPagesToken, [query, externalPagesToken]);

	const [attendance, setAttendance] = useState(null);

	const [attendanceStatus, setAttendanceStatus] = useState('CONFIRMED');

	const attendanceInfo = useMemo(() => ({
		start_date: attendance?.start_date || form?.start_date,
		user: attendance?.doctor || form?.slot?.user,
		place: attendance?.place || form?.slot?.place,
		event_group: attendance?.event_group || form?.slot?.event_group,
		event: attendance?.event || form?.slot?.event,
		allowedToEdit: !attendance?.done && !attendance?.confirmed_at && !attendance?.arrived && !attendance?.canceled && attendanceStatus === 'CONFIRMED'
	}), [
		// attendance?.doctor, attendance?.place, attendance?.event_group, attendance?.event, form?.place, form?.user, form?.place, form?.slot?.user, attendance?.start_date
		attendance,
		attendanceStatus,
		form
	]);

	const [loading, setLoading] = useState(null);

	const googleCalendarUrl = useMemo(() => GoogleUtils.getCalendarUrl(attendanceInfo), [attendanceInfo]);

	const googleMapsDirectionUrl = useMemo(() => GoogleUtils.getDirectionUrl(form?.place?.address), [form?.place?.address]);

	const getAttendance = useCallback(async () => {
		const filter = {
			token: externalToken
		};

		if (!filter.token) {
			return;
		}

		setLoading(true);

		const { data, error } = await findAttendanceByToken(filter);

		if (error) {
			toast.error('Não foi possível buscar as informações do agendamento, tente novamente mais tarde.');
		} else {
			setAttendance(data);
		}

		setLoading(false);
	}, [externalToken]);


	useEffect(() => {
		getAttendance();
	}, [getAttendance, externalToken])

	const handleCancelAttendancePress = useCallback(async () => {
		const data = {
			token: externalToken
		};

		setLoading(true);

		const { error } = await cancelAttendance(data);

		if (error) {
			toast.error('Não foi possível cancelar o agendamento, tente novamente mais tarde.');
		} else {
			toast.success('Agendamento cancelado com sucesso.');

			setAttendanceStatus('CANCELED')
		}

		setLoading(false);
	}, [externalToken]);

	const handleEditAttendancePress = () => NavigationUtils.navigate(history, `/update-timegrid/${externalPagesToken}`);

	return (
		<ScheduledSucessfully
			loading={loading}
			attendance={attendanceInfo}
			settings={settings}
			attendanceStatus={attendanceStatus}
			googleCalendarUrl={googleCalendarUrl}
			googleMapsDirectionUrl={googleMapsDirectionUrl}
			handleCancelAttendancePress={handleCancelAttendancePress}
			handleEditAttendancePress={handleEditAttendancePress}
		/>
	);
}

export default ScheduledSucessfullyContainer;
