import { ToastContainer } from 'react-toastify';
import moment from 'moment-timezone';
import { Route, Switch, withRouter } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import '../node_modules/@amigoapp/doca/dist/css/doca.min.css';

import {
	HomeView,
	PatientFormView,
	SelectEventGroupFormView,
	SelectPaymentMethodFormView,
	SelectEventFormView,
	SelectPlaceFormView,
	SelectTimegridFormView,
	ScheduledSuccessfullyView,
	UpdateTimegridFormView
} from './views';

import { NavigationUtils } from './utils';
import { useHistory } from 'react-router-dom';

import { CompanyProvider } from './contexts/company';

import 'moment/locale/pt-br';

moment.tz.setDefault('America/Recife');
moment.locale('pt-br');

moment.defineLocale('pt-br', {
	weekdaysMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
	months: ['Janeiro', 'Feveriro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
});

const TOAST_OPTIONS = {
	position: 'bottom-center',
	autoClose: 2500,
	hideProgressBar: true,
	newestOnTop: false,
	closeOnClick: true,
	rtl: false,
	pauseOnFocusLoss: true,
	draggable: true,
	pauseOnHover: true
};

const ProtectedRoute = Component => {
	const history = useHistory();

	const token = localStorage.getItem('token');
	const contextInfo = localStorage.getItem('companyInfo');

	if (!token || !contextInfo) {
		return NavigationUtils.navigate(history, '/');
	}

	return <Component />;
};

const App = () => {
	return <>
		<ToastContainer {...TOAST_OPTIONS} />

		<CompanyProvider>
			<Switch>
				<Route path="/patient-form" component={() => ProtectedRoute(PatientFormView)}></Route>
				<Route path="/select-event-group" component={() => ProtectedRoute(SelectEventGroupFormView)}></Route>
				<Route path="/select-payment-method" component={() => ProtectedRoute(SelectPaymentMethodFormView)}></Route>
				<Route path="/select-event" component={() => ProtectedRoute(SelectEventFormView)}></Route>
				<Route path="/select-place" component={() => ProtectedRoute(SelectPlaceFormView)}></Route>
				<Route path="/select-timegrid" component={() => ProtectedRoute(SelectTimegridFormView)}></Route>
				<Route path="/scheduled-succesfully" component={() => ProtectedRoute(ScheduledSuccessfullyView)}></Route>
				<Route path="/update-timegrid/:token" component={() => ProtectedRoute(UpdateTimegridFormView)}></Route>

				<Route path="/" component={HomeView}></Route>
			</Switch>
		</CompanyProvider>
	</>;
};

export default withRouter(App);
