import { Utils } from '../utils';
import httpClient from '../utils/httpClient';

export const list = async filter => {
	const { data, error } = await httpClient({
		method: 'get',
		url: `/agenda-events`,
		params: filter,
		headers: {
			authorization: Utils.getAuthToken()
		}
	});

	return { data, error };
};
