import styled from 'styled-components';

import { GalleryGrid, FlatList } from '../../components';

export const Home = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	max-width: 100vw;
	background-color: #FFF;
`;

Home.FlatList = FlatList;

Home.Header = styled.header`
	display: flex;
	flex-direction: column;
`;

Home.BackgroundImage = styled.img`
	width: 100%;
	object-fit: cover;
	height: 140px !important;
`;

Home.CompanyInfoRow = styled.div`
	display: flex;
	flex-direction: column;
	padding: 16px;
`;

Home.BodyContainer = styled.div`
	display: flex;
	flex-direction: column;
	background: #F2F5F7;
	height: 100%;
`;

Home.BodyContainer.Card = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 0 !important;
`;

Home.GalleryGrid = GalleryGrid;

Home.BodyContainer.Card.Title = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	color: #26282A;
`;

Home.BodyContainer.Card.ContentWrap = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

Home.BodyContainer.Card.Content = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #4E5255;
	margin-top: 16px;
`;

Home.CompanyInfoRow.AvatarContainer = styled.div`
	position: relative;
	margin-bottom: 18px;
`;

Home.CompanyInfoRow.Avatar = styled.img`
	object-fit: cover;
	width: 72px !important;
	height: 72px !important;
	margin-bottom: 18px;
	position: absolute;
	bottom: -30px;
`;

Home.CompanyInfoRow.Content = styled.div`
	display: flex;
	flex-direction: column;
`;

Home.CompanyInfoRow.Content.Title = styled.div`
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	color: #26282A;
`;

Home.CompanyInfoRow.Content.Address = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #4E5255;
`;

Home.CompanyInfoRow.Content.Specialty = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #4E5255;
`;
