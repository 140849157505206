import React from 'react';

import { CardInfo } from '../../../components';

const Plan = ({ plan }) => (
	<CardInfo
		withoutAvatar
		title={plan.name}
	/>
);

export default Plan;
