import styled from 'styled-components';

import { InputField } from '../../components';

export const PatientForm = {};

PatientForm.InputField = styled(InputField)`
	margin-bottom: 24px;
`;

PatientForm.HeaderContainer = styled.div`
	margin-bottom: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`
