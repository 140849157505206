import { DateUtils } from '../../utils';

import { Calendar as StyledCalendar } from './Calendar.styles'

const Calendar = ({
	dates = [],
	enabledDates,
	currentWeek,
	selectedDate,
	variant,
	onChangeDate,
	onChangeWeek,
	disableChangeLastWeek,
	disableChangeNextWeek
}) => {
	return (
		<StyledCalendar>
			<StyledCalendar.Header>
				<StyledCalendar.Header.Month size="medium">{DateUtils.formatDate(currentWeek, 'MMMM YYYY')}</StyledCalendar.Header.Month>

				<StyledCalendar.Header.ActionContainer>
					<StyledCalendar.Button variant="tertiary" disabled={disableChangeLastWeek} onClick={() => onChangeWeek('last')} customClass="doca-mr-1">
						<i className="doca-icon doca-icon--large doca-icon__chevron-left" />
					</StyledCalendar.Button>

					<StyledCalendar.Button variant="tertiary" disabled={disableChangeNextWeek} onClick={() => onChangeWeek('next')}>
						<i className="doca-icon doca-icon--large doca-icon__chevron-right" />
					</StyledCalendar.Button>
				</StyledCalendar.Header.ActionContainer>
			</StyledCalendar.Header>

			<StyledCalendar.DateWeekList className="doca-mt-2">
				{dates.map((date, index) => {
					return (
						<StyledCalendar.DateItemContainer key={index} isLastChild={dates.length - 1 === index}>
							<StyledCalendar.DateItem date={date} variant={variant} onClick={onChangeDate} active={DateUtils.isSame(selectedDate, date)} disabled={!enabledDates.includes(date)} />
						</StyledCalendar.DateItemContainer>
					)
				})}
			</StyledCalendar.DateWeekList>

		</StyledCalendar>
	)
};

export default Calendar;
