import { Utils } from '../utils';
import httpClient from '../utils/httpClient';

export const createAttendance = async attendanceData => {
	const { data, error } = await httpClient({
		method: 'post',
		url: `/attendances`,
		data: attendanceData,
		headers: {
			authorization: Utils.getAuthToken()
		}
	});

	return { data, error };
};

export const findAttendanceByToken = async filter => {
	const { data, error } = await httpClient({
		method: 'get',
		url: '/attendances/find-by-token',
		headers: {
			authorization: `Bearer ${filter.token}`
		}
	});

	return { data, error };
};

export const updateAttendance = async attendanceData => {
	const { data, error } = await httpClient({
		method: 'put',
		url: '/attendances',
		data: attendanceData,
		headers: {
			authorization: `Bearer ${attendanceData.token}`
		}
	});

	return { data, error };
};

export const cancelAttendance = async attendanceData => {
	const { data, error } = await httpClient({
		method: 'put',
		url: `/attendances/cancel`,
		headers: {
			authorization: `Bearer ${attendanceData.token}`
		}
	});

	return { data, error };
};
