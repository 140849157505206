import React, { useCallback } from 'react';

import { GalleryGrid as StyledGalleryGrid } from './GalleryGrid.styles';
import GalleryGridItem from './GalleryGridItem';

import {
	getGridTypeByItemsAmount,
	getGridLayoutByType,
	getItemLayoutName
} from '../../utils/gallery';

const GalleryGrid = ({
	style,
	images,
	renderItem,
	isRemoveDisabled,
	onRemoveImagesPress,
	onImagePress,
}) => {
	const imagesToShow = images.slice(0, 5);
	const gridType = getGridTypeByItemsAmount(imagesToShow.length);
	const gridLayout = getGridLayoutByType(gridType);

	const renderDefaultItem = useCallback(({ item, itemLayout, index }) => {
		return (
			<GalleryGridItem
				images={images}
				index={index}
				itemHeight={itemLayout.height}
				itemWidth={itemLayout.width}
				uri={item.thumb_url}
				onPress={onImagePress}
			/>
		);
	}, [images, onImagePress]);

	return images.length ? (
		<StyledGalleryGrid style={style}>
			<StyledGalleryGrid.MultipleImages>
				{!!onRemoveImagesPress && (
					<StyledGalleryGrid.Button
						disabled={isRemoveDisabled}
						icon="close"
						size="small"
						type="white"
						onPress={onRemoveImagesPress}
					/>
				)}

				{imagesToShow.map((item, index) => {
					const itemLayoutName = getItemLayoutName(index + 1);
					const itemLayout = gridLayout[itemLayoutName] || gridLayout.default;
					const itemKey = item.id;

					const renderOptions = {
						index,
						item,
						itemLayout
					};

					return (
						<StyledGalleryGrid.Row
							key={itemKey}
							itemWidth={itemLayout.width}>
							{renderItem
								? renderItem(renderOptions)
								: renderDefaultItem(renderOptions)
							}
						</StyledGalleryGrid.Row>
					);
				})}
			</StyledGalleryGrid.MultipleImages>
		</StyledGalleryGrid>
	) : null;
};

GalleryGrid.Item = GalleryGridItem;

export default GalleryGrid;
