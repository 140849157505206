import styled from 'styled-components';

import { Button, Text } from '../../../components';

export const SlotItem = styled.div`
	display: flex;
	flex-direction: column;
	padding: 16px;
	background: white;
	border: 1px solid #D9E1E8;
	border-radius: 8px;
`;

SlotItem.Button = Button;

SlotItem.SlotContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

SlotItem.SlotContainer.Info = styled.div`
	display: flex;
	flex-direction: column;
`;

SlotItem.SlotContainer.Info.Turn = Text;

SlotItem.SlotContainer.Info.Slots = styled.div`
	display: flex;
	margin-top: 8px;
`;
