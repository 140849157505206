import { getClasses } from '../../utils/styler';

const InputField = ({
	disabled,
	type,
	placeholder,
	className = 'doca-form__field',
	name,
	label,
	maxLength,
	iconLeft,
	iconRight,
	feedback,
	variant,
	required,
	children,
	onChange,
	value,
	...props
 }) => (
	<div className={className}>
		{label && <label className={getClasses({ ...props }, {
			'doca-form__label': true,
			[`doca-form-label--${required && 'required'}`]: variant,
		})}>
			{label}
		</label>}
		<div className={getClasses({ ...props }, {
				'doca-form-control__icon-container': true,
				'doca-form-control__icon-container--left': iconLeft && !iconRight,
				'doca-form-control__icon-container--right': !iconLeft && iconRight,
				'doca-form-control__icon-container--left-right': iconLeft && iconRight
			})}>

			{iconLeft && <i className={getClasses({ ...props }, {
				'doca-icon': true,
				[`doca-icon__${iconLeft}`]: iconLeft,
			})}></i>}

			<input className={getClasses({ ...props }, {
				'doca-form-control': true,
				[`doca-form-control--${variant}`]: variant,
			})} name={name}
				placeholder={placeholder}
				value={value}
				disabled={disabled}
				onChange={onChange}
				type={type}
				maxLength={maxLength}
				required={required}>
				{children}
			</input>

			{iconRight && <i className={getClasses({ ...props }, {
				'doca-icon': true,
				[`doca-icon__${iconRight}`]: iconRight,
			})}></i>}
		</div>

		{feedback && <span className={getClasses({ ...props }, {
			'doca-form__feedback': true,
			[`doca-form__feedback--${variant}`]: variant,
		})}>
			{feedback}
		</span>}
	</div>
);

export default InputField;
