import React from 'react';

import { DateUtils } from '../../../utils';

const DateItem = ({ date, active, disabled, variant, onClick }) => {
	return (
		<button className={`doca-shortcut doca-shortcut--small ${DateUtils.isToday(date) ? 'doca__current-day' : ''} doca-text--center w-100 ${active ? `doca-shortcut--${variant}` : 'doca-shortcut--borderless'}`} onClick={() => onClick && onClick(date)} disabled={disabled}>
			<p className="doca-shortcut__title doca-mt-0">
				{DateUtils.formatDate(date, 'dd', 'YYYY-MM-DD')}
			</p>

			<p className="doca-shortcut__title">
				{DateUtils.formatDate(date, 'D', 'YYYY-MM-DD')}
			</p>
		</button>
	)
};

export default DateItem;
