import { getClasses } from '../../utils/styler';

import defaultImage from '../../assets/images/avatar.png';

const Avatar = ({ size, rounded, url, ...props }) => (
	<img
		alt="Imagem"
		className={getClasses({ ...props }, {
			'doca-avatar': true,
			[`doca-avatar--${size}`]: !!size,
			[`doca-avatar--${rounded && 'rounded'}`]: !!rounded,
		})}
		src={url || defaultImage}
	/>
);

export default Avatar;
