import { DateUtils } from './';

export const getCalendarUrl = info => {
	const infoMounted = {
		text: `Consulta com Dr(a). ${info?.user?.name}`,
		location: `${info?.event_group?.type === 'TELEMEDICINE' ? 'Online' : (info?.place?.address || '')}`,
		dates: `${DateUtils.formatDate(info?.start_date, 'YYYYMMDD[T]HHmmss')}/${DateUtils.formatDate(info?.end_date, 'YYYYMMDD[T]HHmmss')}`,
		ctz: 'America/Recife'
	};

	return `https://www.google.com/calendar/render?action=TEMPLATE&text=${infoMounted.text}&location=${infoMounted.location}&dates=${infoMounted.dates}&ctz=${infoMounted.ctz}`;
};

export const  getDirectionUrl = address => `https://www.google.com/maps/dir/?api=1&destination=${address}`
