import styled from 'styled-components';

import { SPACING } from '../../constants/theme.js';

export const Container = styled.main`
	display: flex;
	width: 100%;
	flex-direction: column;
	padding: ${({ padded }) => padded ? SPACING.medium : 0};
`;
