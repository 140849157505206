import React from 'react';

import Separator from '../Separator';

import { FlatList as StyledFlatList } from './FlatList.styles'

const FlatList = ({
	data,
	itemClassName,
	separator,
	renderItem,
	renderKeyExtractor,
	maxHeight
}) => (
	<StyledFlatList maxHeight={maxHeight}>
		{data.map((item, index) => {
			const isLastChild = index === data.length - 1;
			const showSeparator = !isLastChild;

			return (
				<div className={itemClassName} key={renderKeyExtractor ? renderKeyExtractor(item) : index}>
					{renderItem(item, index)}

					{showSeparator && separator && <Separator spacing={separator} />}
				</div>
			);
		})}
	</StyledFlatList>
);

export default FlatList;
