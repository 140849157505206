import { getClasses } from '../../utils/styler';

const Button = ({ variant, size, block, customClass, onClick, disabled, children, style, type, ...props }) => (
	<button style={style} className={getClasses({ ...props }, {
		'doca-button': true,
		[`doca-button--${variant}`]: variant,
		[`doca-button--${size}`]: !!size,
		[`doca-button--${block && 'block'}`]: !!block,
		[customClass]: customClass
	})} disabled={disabled} onClick={onClick} type={type}>
		{children}
	</button>
);

export default Button;
