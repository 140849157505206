import React from "react";

import { ScheduledPage as StyledScheduledPage } from "./ScheduledSucessfully.styles.js";

import { DateUtils } from "../../utils/index.js";

import { Loading  } from '../../components';

import defaultImage from "../../assets/images/avatar.png";
import defaultBackground from "../../assets/images/background-default.png";

import { DoctorItem } from '../';

const ICON_BY_GROUPS = {
	TELEMEDICINE: 'video',
	ATTENDANCE: 'location-dot',
	COMEBACK: 'location-dot'
};

const ATTENDANCE_STATUS = {
	CONFIRMED: 'CONFIRMED',
	CANCELED: 'CANCELED'
};

function ScheduledSucessfully({
	settings,
	loading,
	attendance,
	attendanceStatus,
	googleCalendarUrl,
	googleMapsDirectionUrl,
	handleEditAttendancePress,
	handleCancelAttendancePress
}) {
	return (
		<StyledScheduledPage>
			<StyledScheduledPage.Header>
				<StyledScheduledPage.BackgroundImage
					src={settings?.background?.url || defaultBackground}
				/>

				<StyledScheduledPage.CompanyInfoRow>
					<StyledScheduledPage.CompanyInfoRow.AvatarContainer>
						<StyledScheduledPage.CompanyInfoRow.Avatar
							className="doca-avatar doca-avatar--rounded"
							src={settings?.logo?.url || defaultImage}
						/>
					</StyledScheduledPage.CompanyInfoRow.AvatarContainer>

					<StyledScheduledPage.CompanyInfoRow.Content>
						<StyledScheduledPage.CompanyInfoRow.Content.Title>
							{settings.name}
						</StyledScheduledPage.CompanyInfoRow.Content.Title>

						{settings?.specialty && (
							<StyledScheduledPage.CompanyInfoRow.Content.Specialty className="doca-mt-2">
								<i className="doca-icon doca-icon--regular doca-icon__user-doctor m-r-4"></i>

								{settings?.specialty}
							</StyledScheduledPage.CompanyInfoRow.Content.Specialty>
						)}

						<StyledScheduledPage.CompanyInfoRow.Content.Address className="doca-mt-1">
							{settings?.address_city && (
								<span>{settings.address_city}, </span>
							)}

							{settings.address_state}
						</StyledScheduledPage.CompanyInfoRow.Content.Address>
					</StyledScheduledPage.CompanyInfoRow.Content>
				</StyledScheduledPage.CompanyInfoRow>
			</StyledScheduledPage.Header>

			<StyledScheduledPage.Body className="doca-mt-4">
				{loading ? (
					<div className="doca-page__box b-radius-0">
						<Loading />
					</div>
				) : (
					<StyledScheduledPage.CardContainer className="doca-page__box b-radius-0">
						<StyledScheduledPage.CardContainer.Title
							size="large"
							weight="semibold"
						>
							<StyledScheduledPage.Text
								size="large"
								weight="semibold"
							>
								{attendanceStatus ===
								ATTENDANCE_STATUS.CONFIRMED
									? "Agendamento confirmado"
									: "Agendamento cancelado"}
							</StyledScheduledPage.Text>
						</StyledScheduledPage.CardContainer.Title>

						<StyledScheduledPage.Text>
							<i className="doca-icon doca-icon__calendar-lines doca-mr-2" />

							{DateUtils.formatDate(
								attendance?.start_date,
								"dddd DD, MMM YYYY HH[h]:mm"
							)}
						</StyledScheduledPage.Text>

						<div className="ds-flex doca-mt-2">
							<div>
								<i
									className={`doca-icon doca-mr-2 doca-icon__${
										ICON_BY_GROUPS[
											attendance?.event_group?.type
										]
									}`}
								/>
							</div>

							{attendance?.event_group?.type !==
							"TELEMEDICINE" ? (
								<div className="ds-flex flex-column">
									{attendance?.place?.address ? (
										<>
											<div>
												{
													attendance?.place
														?.address_address
												}{" "}
												{attendance?.place
													.address_number
													? `- ${attendance?.place.address_number}`
													: ""}
											</div>
											<div>
												{attendance?.place.address_city}
												{attendance?.place.address_state
													? `-${attendance?.place.address_state}`
													: ""}
											</div>
										</>
									) : (
										<div>Não informado</div>
									)}
								</div>
							) : (
								<span>{attendance?.event_group.name}</span>
							)}
						</div>

						<StyledScheduledPage.Text
							marginTop={8}
							marginBottom={16}
						>
							<i className="doca-icon doca-icon__stethoscope doca-mr-2" />

							{attendance?.event?.preview_name ||
								attendance?.event?.name}
						</StyledScheduledPage.Text>

						<StyledScheduledPage.Text
							weight="semibold"
							marginBottom={12}
						>
							Profissional:
						</StyledScheduledPage.Text>

						<DoctorItem withoutAvatar doctor={attendance?.user} />

						{attendance?.event_group?.type !== "TELEMEDICINE" && (
							<a
								className="doca-button text--primary doca-mt-4 doca-button--tertiary doca-button--block"
								target="_blank"
								rel="noreferrer"
								href={googleMapsDirectionUrl}
								disabled={
									ATTENDANCE_STATUS.CANCELED ===
									attendanceStatus
								}
							>
								<i className="doca-icon doca-icon--large doca-icon__diamond-turn-right" />
								Como chegar?
							</a>
						)}

						<StyledScheduledPage.Separator spacing={16} />

						<StyledScheduledPage.FlexContainer>
							<a
								className="doca-button doca-button--tertiary doca-button--block"
								target="_blank"
								rel="noreferrer"
								href={googleCalendarUrl}
								disabled={
									ATTENDANCE_STATUS.CANCELED ===
									attendanceStatus
								}
							>
								<i className="doca-icon doca-icon--large doca-icon__calendar-plus" />
								Calendário
							</a>

							<StyledScheduledPage.Button
								block
								variant="tertiary"
								onClick={handleEditAttendancePress}
								disabled={!attendance.allowedToEdit}
							>
								<i className="doca-icon doca-icon--large doca-icon__pen-to-square" />
								Modificar
							</StyledScheduledPage.Button>

							<StyledScheduledPage.Button
								customClass={
									attendance.allowedToEdit && "text--danger"
								}
								block
								variant="tertiary"
								onClick={handleCancelAttendancePress}
								disabled={!attendance.allowedToEdit}
							>
								<i className="doca-icon doca-icon--large doca-icon__xmark-large" />
								Cancelar
							</StyledScheduledPage.Button>
						</StyledScheduledPage.FlexContainer>
					</StyledScheduledPage.CardContainer>
				)}
			</StyledScheduledPage.Body>
		</StyledScheduledPage>
	);
};

export default ScheduledSucessfully;
