
import "react-responsive-carousel/lib/styles/carousel.min.css";
import React from 'react';

import {Carousel as StyledCarousel} from './Carousel.styles';

function CarouselContainer({
	images,
	onClose
}) {
    return (
		<StyledCarousel>
			<div className="ds-flex ds-flex__align--center doca-mb-4">
				<StyledCarousel.Button variant="tertiary" onClick={onClose} marginRight={16}>
					<i className="doca-icon doca-icon--large doca-icon__chevron-left" />
				</StyledCarousel.Button>

				<StyledCarousel.Text size="large" weight="semibold">{images.length > 1 ? 'Fotos' : 'Foto'}</StyledCarousel.Text>
			</div>

			<StyledCarousel.Body className="carousel__container">
				<StyledCarousel.Slider
					showStatus={false}
					showArrows={false}
					infiniteLoop
					emulateTouch
				>
					{images.map((image, index) => {
						return (
							<StyledCarousel.ImageContainer>
								<img alt={index} src={image.thumb_url} />
							</StyledCarousel.ImageContainer>
						)
					})}
				</StyledCarousel.Slider>
			</StyledCarousel.Body>
		</StyledCarousel>
	);
}

export default CarouselContainer;
