import axios from 'axios';

const baseURL = 'https://api.agende.ai';

const client = axios.create({ baseURL });

const ResponseInterceptor = async response => {
	return Promise.resolve({ data: response.data.data });
};

const ErrorInterceptor = error => {
	return Promise.resolve({
		error: error.response ? error.response.data : error.message
	});
};

client.interceptors.response.use(ResponseInterceptor, ErrorInterceptor);

export default client;
