import { isObject, snakeCase } from 'lodash';

const objectToSnakeCase = (params, { ignore }) => Object.keys(params).reduce((acc, key) => {
	if (ignore.includes(key)) {
		return {
			...acc,
			[snakeCase(key)]: params[key]
		};
	}

	return {
		...acc,
		[snakeCase(key)]: toSnakeCase(params[key])
	};
}, {});

const arrayToSnakeCase = (items, { ignore }) => items.map(value => isObject(value) ? objectToSnakeCase(value, { ignore }) : value);

const toSnakeCase = (content, { ignore = [] } = {}) => {
	if (Array.isArray(content)) {
		return arrayToSnakeCase(content, { ignore });
	}

	if (isObject(content)) {
		return objectToSnakeCase(content, { ignore });
	}

	return content;
};

export default toSnakeCase;
