import React, { useContext, useState, useMemo, useEffect } from 'react';
import { useCallback } from 'react';

const CompanyContext = React.createContext({
	settings: {},
	scheduleSettings: {},
	form: {
		event_group: null,
		place: null,
		event: null,
		slot: null,
		user: null,
		patient: null
	}
});

export const CompanyProvider = ({ children }) => {
	const patient = useMemo(() => {
		const patientFromLocalStorage = localStorage.getItem('patient');

		if (!patientFromLocalStorage) {
			return null;
		}

		return JSON.parse(patientFromLocalStorage);
	}, []);

	const [info, setInfo] = useState({
		settings: {},
		scheduleSettings: {},
		form: {
			event_group: null,
			place: null,
			event: null,
			slot: null,
			user: null,
			patient
		}
	});

	const clearFormAndStorage = useCallback(() => {
		setInfo(values => ({
			...values,
			form: {
				patient
			}
		}));

		localStorage.removeItem('info');
		localStorage.removeItem('token');
		localStorage.removeItem('companyInfo');
	}, [patient])

	useEffect(() => {
		const localStorageInfo = localStorage.getItem('info');
		const localStorageInfoParsed = localStorageInfo ? JSON.parse(localStorageInfo) : null;

		if (localStorageInfoParsed) {
			setInfo(localStorageInfoParsed);
		}
	}, []);

	useEffect(() => {
		localStorage.setItem('info', JSON.stringify(info));
	}, [info]);

	return (
		<CompanyContext.Provider value={{
			...info,
			setInfo,
			clearFormAndStorage
		}}>
			{children}
		</CompanyContext.Provider>
	)
}

export const useCompanyContext = () => useContext(CompanyContext);
