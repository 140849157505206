import React from 'react';

import { CardInfo } from '../../../components';

const Place = ({ place, titleSize, titleVariation, titleWeight }) => (
	<CardInfo
		withoutAvatar
		subtitle={place.address || 'Sem endereço'}
		subtitleIcon="location-dot"
		title={place.name}
		titleWeight={titleWeight}
		titleVariation={titleVariation}
		titleSize={titleSize}
		subtitleSpacing={4}
	/>
);

export default Place;
