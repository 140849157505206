import styled from 'styled-components';

import { SPACING } from '../../constants/theme';

export const EmptyState = styled.div`
	width: 100%;
	align-self: center;
	display: flex;
	padding: ${SPACING.large} 0;
`;
