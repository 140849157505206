import styled from 'styled-components';

import { SPACING, BORDER_RADIUS } from '../../constants/theme';

export const GalleryGrid = styled.div`
	margin-bottom: ${SPACING.small};
`;

GalleryGrid.MultipleImages = styled.div`
	align-items: center;
	align-self: center;
	position: relative;
	flex-direction: row;
	flex-wrap: wrap;
	display: flex;
`;

GalleryGrid.Row = styled.div`
	width: ${({ itemWidth }) => itemWidth && itemWidth}%;
	padding: 4px 8px 4px 0;
	min-height: 108px;
`;

GalleryGrid.Image = styled.img`
	border-radius: ${BORDER_RADIUS.medium};
`;

GalleryGrid.Button = styled.button`
	width: 40px;
	z-index: 2;
	position: absolute;
	top: ${SPACING.small};
	right: ${SPACING.large};
`;
