import httpClient from '../utils/httpClient';
import toSnakeCase from '../utils/objectToSnakeCase';
import { getAuthToken } from '../utils/utils';

export const create = async patientData => {
	const { data, error } = await httpClient({
		method: 'post',
		url: `/patients`,
		data: toSnakeCase(patientData),
		headers: {
			authorization: getAuthToken()
		}
	});

	return { data, error };
};

